<template>
  <div>
    <div class="card">
      <div class="card-body pb-0 pt-2">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="card-title">Top Viewed Videos</h4>
          <div class="d-flex overall-count pb-2">
            <div class="mr-2">Overall Count</div>
            <Switches
              v-model="overallCount"
              type-bold="true"
              color="success"
              class="m-0"
            ></Switches>
          </div>
        </div>
        <JsonExcel
            class="btn p-0 d-flex justify-content-end"
            :data="videos"
            name="video.csv"
            type="csv">
            <i class="mdi mdi-download"></i>
            Download
          </JsonExcel>
      </div>
      <div class="card-body py-2">
        <b-table
          :items="videos"
          :fields="fields"
          responsive="sm"
          :per-page="9"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template v-slot:cell(icon)>
            <!-- <img
              style="width: 50px; height: 50px"
              src="https://mymedisage.com/storage/icons/expert/Medisage-logo-700-600_v2.jpg"
              alt=""
            /> -->
            <i class="ri-video-line" style="font-size: 25px"></i>
          </template>
          <template v-slot:cell(video)="row">
            <!-- <b-link :href="row.item.url_link">
              {{
                row.value != "" ? row.value.substring(0, 50) + "..." : row.value
              }}
            </b-link> -->
            <b>
              <a
                :href="'https://mymedisage.com/video/' + row.item.url_link"
                target="_blank"
                style="color: inherit"
                >{{
                  row.value != ""
                    ? row.value.substring(0, 50) + "..."
                    : row.value
                }}</a
              ></b
            >
          </template>
        </b-table>
        <div class="row">
          <div class="col">
            <div class="dataTables_pa ginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../../mixins/filterData";
import Switches from "vue-switches";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    Switches,
    JsonExcel
  },
  props: ["filters"],
  mixins: [filterMixins],
  data() {
    return {
      videos: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25, 50],
      overallCount: false,
      sortBy: "total_views",
      sortDesc: true,
      fields: [
        { key: "icon", label: "" },
        { key: "video", sortable: true, label: "Title" },
        { key: "total_liked", sortable: true, label: "Likes" },
        { key: "total_insightful", sortable: true, label: "Insightful" },
        { key: "total_views", sortable: true, label: "Views" },
      ],
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getTopViewedVideos();
      },
    },
    overallCount() {
      this.getTopViewedVideos();
    },
  },
  methods: {
    async getTopViewedVideos() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/videos/views?&overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.videos = response.data.videos;
          this.totalRows = this.videos.length;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/videos/views",
            {
              params: searchfiltersParams,
            }
          );
          this.videos = response.data.videos;
          this.totalRows = this.videos.length;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getTopViewedVideos();
  },
};
</script>
