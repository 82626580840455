<!-- Top 10 Speciality Chart -->
<template>
  <div class="card">
    <div class="card-body pb-0 pt-2 mb-4">
      <div class="d-flex justify-content-between align-items-center pb-3">
        <h4 class="card-title">Top 10 Specialities</h4>
        <div class="d-flex overall-count pb-2">
          <div class="mr-2">Overall Count</div>
          <Switches
            v-model="overallCount"
            type-bold="true"
            color="success"
            class="mb-0"
          ></Switches>
        </div>
      </div>
      <v-chart :options="chartOptions" autoresize :key="key" />
    </div>
  </div>
</template>

<style>
.echarts {
  width: 526px !important;
}
</style>

<script>
import appConfig from "@/app.config";
import ECharts from "vue-echarts";
import "echarts/lib/chart/pie";
import Switches from "vue-switches";
import "echarts/lib/component/legend";
import "echarts/lib/component/title";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/polar";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/grid";
import "echarts/lib/component/axis";
import filterMixins from "../../../../mixins/filterData";

// import _ from "lodash";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    "v-chart": ECharts,
    Switches,
  },
  data() {
    return {
      overallCount: false,
      chartOptions: {
        visualMap: {
          show: true,
        },
        tooltip: {
          trigger: "item",
          formatter: function (data) {
            return `${data.name}<br />
                Total Views : ${data.value}<br />
                Total Viewers : ${data.data.total_unique_viewers}<br />
                Total Liked : ${data.data.total_liked}`;
          },
        },
        legend: {
          orient: "horizontal",
          left: "left",
          data: [],
          textStyle: {
            color: "#6e0909",
          },
        },
        color: [
          "#001558",
          "#0E2B86",
          "#1537A4",
          "#2A4DBA",
          "#2351DE",
          "#325DE5",
          "#3D68EE",
          "#4670F3",
          "#5E84F9",
          "#7193FD",
          "#9AB3FF",
          "#BECEFE",
        ],
        series: [
          {
            name: "Speciality Data",
            type: "pie",
            radius: "50%",
            center: ["55%", "65%"],
            data: [],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      data: {},
      total_views: 0,
      key: 0,
      counts: 0,
      allData: {},
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getSpecialitiesData();
      },
    },
    overallCount() {
      this.getSpecialitiesData();
    },
  },
  methods: {
    getRandomColor() {
      let color = "hsl(" + Math.random() * 360 + ", 100%, 75%)";
      return color;
    },

    async getSpecialitiesData() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/videos/specialities/views?&overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.data = response.data.specialities;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/videos/specialities/views",
            {
              params: searchfiltersParams,
            }
          );
          this.data = response.data.specialities;
        }
        this.loadData();
      } catch (err) {
        console.log(err);
      }
    },

    loadData() {
      this.chartOptions.series[0].data = [];
      // const data = _.cloneDeep(this.filters);
      const data = this.data;
      this.counts = 0;
      const compressed = data.reduce((a, c) => {
        const dateIndex = a.findIndex(
          (o) =>
            o.speciality === c.speciality &&
            (c.speciality != null ||
              c.speciality != "null" ||
              c.speciality != "")
        );
        if (dateIndex !== -1) a[dateIndex].total_views += c.total_views;
        if (dateIndex !== -1)
          a[dateIndex].total_unique_viewers += c.total_unique_viewers;
        if (dateIndex !== -1)
          a[dateIndex].total_liked += parseInt(c.total_liked);
        if (dateIndex !== -1)
          a[dateIndex].total_insightful += parseInt(c.total_insightful);
        else
          a.push({
            speciality: c.speciality,
            name: c.speciality,
            value: c.total_views,
            total_views: c.total_views,
            total_unique_viewers: c.total_unique_viewers,
            total_liked: parseInt(c.total_liked),
            total_insightful: parseInt(c.total_insightful),
          });
        return a;
      }, []);
      compressed.sort(function (a, b) {
        return b.total_views - a.total_views;
      });
      compressed.forEach((f, i) => {
        if (i < 10) {
          this.chartOptions.series[0].data.push(f);
        }
      });
      this.chartOptions.legend.data = this.chartOptions.series[0].data.map(
        (obj) => obj.name
      );
      this.key += 1;
    },
  },
  mounted() {
    this.getSpecialitiesData();
  },
};
</script>
