<template>
  <div>
    <div class="card">
      <div class="card-body pb-0 pt-2">
        <div class="d-flex justify-content-between align-items-center">
          <h4 class="card-title">Engagement</h4>
          <div class="d-flex overall-count pb-2">
            <div class="mr-2">Overall Count</div>
            <Switches
              v-model="overallCount"
              type-bold="true"
              color="success"
              class="m-0"
            ></Switches>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <div class="card-body pb-0 pt-2">
              <h4 class="card-title">Views</h4>
              <h3>{{ count.total_views }}</h3>
            </div>
            <div class="card-body border-top py-2">
              <div class="">
                <template v-if="statsData[0].subvalue">
                  <span
                    class="badge font-size-11"
                    :class="
                      statsData[0].subvalue > 0
                        ? 'badge-soft-success'
                        : 'badge-soft-danger'
                    "
                  >
                    <i
                      class="mdi"
                      :class="
                        statsData[0].subvalue > 0
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      "
                    ></i>
                    {{ statsData[0].subvalue }} %
                  </span>
                  <span class="text-muted ml-2">{{
                    statsData[0].subtext
                  }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div>
            <div class="card-body pb-0 pt-2">
              <h4 class="card-title">Unique Viewers</h4>
              <h3>{{ count.total_unique_viewers }}</h3>
            </div>
            <div class="card-body border-top py-2">
              <div class="">
                <template v-if="statsData[1].subvalue">
                  <span
                    class="badge font-size-11"
                    :class="
                      statsData[1].subvalue > 0
                        ? 'badge-soft-success'
                        : 'badge-soft-danger'
                    "
                  >
                    <i
                      class="mdi"
                      :class="
                        statsData[1].subvalue > 0
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      "
                    ></i>
                    {{ statsData[1].subvalue }} %
                  </span>
                  <span class="text-muted ml-2">{{
                    statsData[1].subtext
                  }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div>
            <div class="card-body pb-0 pt-2">
              <h4 class="card-title">Insightful</h4>
              <h3>{{ count.total_insightful }}</h3>
            </div>
            <div class="card-body border-top py-2">
              <div class="">
                <template v-if="statsData[2].subvalue">
                  <span
                    class="badge font-size-11"
                    :class="
                      statsData[2].subvalue > 0
                        ? 'badge-soft-success'
                        : 'badge-soft-danger'
                    "
                  >
                    <i
                      class="mdi"
                      :class="
                        statsData[2].subvalue > 0
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      "
                    ></i>
                    {{ statsData[2].subvalue }} %
                  </span>
                  <span class="text-muted ml-2">{{
                    statsData[2].subtext
                  }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div>
            <div class="card-body pb-0 pt-2">
              <h4 class="card-title">Likes</h4>
              <h3>{{ count.total_liked }}</h3>
            </div>
            <div class="card-body border-top py-2">
              <div class="">
                <template v-if="statsData[3].subvalue">
                  <span
                    class="badge font-size-11"
                    :class="
                      statsData[3].subvalue > 0
                        ? 'badge-soft-success'
                        : 'badge-soft-danger'
                    "
                  >
                    <i
                      class="mdi"
                      :class="
                        statsData[3].subvalue > 0
                          ? 'mdi-menu-up'
                          : 'mdi-menu-down'
                      "
                    ></i>
                    {{ statsData[3].subvalue }} %
                  </span>
                  <span class="text-muted ml-2">{{
                    statsData[3].subtext
                  }}</span>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import filterMixins from "../../../../mixins/filterData";
import Switches from "vue-switches";

/**
 * Dashboard component
 */
export default {
  components: {
    Switches,
  },
  props: ["filters"],
  mixins: [filterMixins],
  data() {
    return {
      count: 0,
      statsData: [
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
        {
          subvalue: 0,
          subtext: "From Previous Period",
        },
      ],
      overallCount: false,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getCount();
      },
    },
    overallCount() {
      this.getCount();
    },
  },
  methods: {
    async getCount() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        if (this.overallCount == true) {
          const response = await this.$http.get(
            appConfig.api_base_url + "/videos/count?&overall=true",
            {
              params: searchfiltersParams,
            }
          );
          this.count = response.data;
        } else {
          const response = await this.$http.get(
            appConfig.api_base_url + "/videos/count",
            {
              params: searchfiltersParams,
            }
          );
          this.count = response.data;
        }

        this.statsData[0].subvalue =
          this.count.previous_period_total_views !== 0
            ? (
                ((this.count.total_views -
                  this.count.previous_period_total_views) /
                  this.count.previous_period_total_views) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[0].subvalue = !isNaN(this.statsData[0].subvalue)
          ? this.statsData[0].subvalue
          : "0";

        this.count.total_views = !isNaN(this.count.total_views)
          ? this.count.total_views
          : "0";

        this.statsData[1].subvalue =
          this.count.previous_period_total_unique_viewers !== 0
            ? (
                ((this.count.total_unique_viewers -
                  this.count.previous_period_total_unique_viewers) /
                  this.count.previous_period_total_unique_viewers) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[1].subvalue = !isNaN(this.statsData[1].subvalue)
          ? this.statsData[1].subvalue
          : "0";

        this.count.total_unique_viewers = !isNaN(
          this.count.total_unique_viewers
        )
          ? this.count.total_unique_viewers
          : "0";

        this.statsData[2].subvalue =
          parseInt(this.count.previous_period_total_insightful) !== 0
            ? (
                ((parseInt(this.count.total_insightful) -
                  parseInt(this.count.previous_period_total_insightful)) /
                  parseInt(this.count.previous_period_total_insightful)) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[2].subvalue = !isNaN(this.statsData[2].subvalue)
          ? this.statsData[2].subvalue
          : "0";

        this.count.total_insightful = !isNaN(
          parseInt(this.count.total_insightful)
        )
          ? parseInt(this.count.total_insightful)
          : "0";

        this.statsData[3].subvalue =
          parseInt(this.count.previous_period_total_liked) !== 0
            ? (
                ((parseInt(this.count.total_liked) -
                  parseInt(this.count.previous_period_total_liked)) /
                  parseInt(this.count.previous_period_total_liked)) *
                100
              ).toFixed(2)
            : "0";
        this.statsData[3].subvalue = !isNaN(this.statsData[3].subvalue)
          ? this.statsData[3].subvalue
          : "0";

        this.count.total_liked = !isNaN(parseInt(this.count.total_liked))
          ? parseInt(this.count.total_liked)
          : "0";
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getCount();
  },
};
</script>
