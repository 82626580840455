<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Filters @filters="setFilters" />
    <div class="row">
      <div class="col-lg-6">
        <Engagement :filters="filters"></Engagement>
        <div>
          <SpecialityWise :filters="filters"></SpecialityWise>
        </div>
      </div>
      <div class="col-lg-6">
        <TopViewedVideos :filters="filters"></TopViewedVideos>
      </div>
    </div>
    <div>
      <DailyTrend :filters="filters"></DailyTrend>
    </div>
    <div>
      <DemographicWise :filters="filters"></DemographicWise>
    </div>
    <div>
      <MonthlyTrend :filters="filters"></MonthlyTrend>
    </div>
    <!-- <div class="col-lg-2">
        <AvgViewDuration :filters="filters"></AvgViewDuration>
      </div>
      <div class="col-lg-2">
        <TotalWatchtime :filters="filters"></TotalWatchtime>
      </div>
      <div class="col-lg-2">
        <TotalVideosViewed :filters="filters"></TotalVideosViewed>
      </div>
    <div class="row">
      <div class="col-lg-4">
        <UserWise :filters="filters"></UserWise>
      </div>
      <div class="col-lg-4">
        <DeviceWise :filters="filters"></DeviceWise>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <NewUsers :filters="filters"></NewUsers>
      </div>
    </div> -->
    <!-- <DropoutUsers :filters="filters"></DropoutUsers> -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Filters from "../videos/filters.vue";
import Engagement from "./videoComponents/Engagement.vue";
import TopViewedVideos from "./videoComponents/TopViewedVideos.vue";
import DemographicWise from "./videoComponents/DemographicWise.vue";
import SpecialityWise from "./videoComponents/SpecialityWise.vue";
import MonthlyTrend from "./videoComponents/MonthlyTrend.vue";
import DailyTrend from "./videoComponents/DailyTrend.vue";
// import TotalVideosViewed from "./videoComponents/TotalVideosViewed.vue";
// import TotalWatchtime from "./videoComponents/TotalWatchtime.vue";
// import UserWise from "./videoComponents/UserWise.vue";
// import AvgViewDuration from "./videoComponents/AvgViewDuration.vue";
// import DeviceWise from "./videoComponents/DeviceWise.vue";
// import NewUsers from "./videoComponents/NewUsers.vue";
// import DropoutUsers from "./videoComponents/DropoutUsers.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Filters,
    Engagement,
    TopViewedVideos,
    DemographicWise,
    SpecialityWise,
    MonthlyTrend,
    DailyTrend,
    // TotalVideosViewed,
    // TotalWatchtime,
    // UserWise,
    // AvgViewDuration,
    // DeviceWise,
    // NewUsers,
    // DropoutUsers,
  },
  data() {
    return {
      title: "Videos",
      items: [
        {
          text: "Medisage",
        },
        {
          text: "Videos",
          active: true,
        },
      ],
      summary: {},
      previousSummary: {},
      state_summary: {},
      filters: {},
      reload: 0,
      params: {},
      loading: true,
    };
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    setFilters(v) {
      this.filters = v;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        // this.init();
      },
    },
  },
};
</script>
